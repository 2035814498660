// 약상세
export const drug = {
  1: [
    {
      name: {
        ko: '피나스테리드',
        en: 'Finasteride',
      },
      efficacy: '성인 남성(만 18~41세)의 남성형 탈모증(안드로겐 탈모증)의 치료',
      dosage:
        '일반적으로 1일 1회 1정(1mg)을 식사와 무관하게 복용합니다.\n보통 3개월 이상 복용해야 치료 효과를 볼 수 있기 때문에 치료효과를 유지하기 위해서는 지속적으로 복용해야 합니다.\n만약 복용을 중단하면 12개월 이내에 치료 효과는 사라지게 됩니다.',
      caution:
        '1. 소아 및 여성은 복용하면 안 됩니다.\n2. 임부 또는 임신 가능성이 있는 여성의 경우 남성 태아에 위험을 초래할 가능성이 있으므로 절대 복용하면 안 됩니다. 또한 부서진 약 조각을 만질 경우에도 피부를 통해 흡수될 수 있기 때문에 절대 만지면 안 됩니다.\n3. 양성전립샘비대증 등으로 인해 다른 5α-환원효소 억제제를 복용하고 있는 환자는 복용하면 안 됩니다.\n4. 간에서 대사 되기 때문에 간 질환을 앓고 있거나 간 기능 이상이 있는 경우에는 주의해야 합니다.',
      side_effect:
        '1. 발기부전, 성욕감퇴, 사정량 감소 등이 있을 수 있습니다.\n2. 남자라도 유방에 멍울이 만져지거나 커지는 현상 및 통증, 또는 유두에서 분비물이 나오는 등 유방의 변화가 있을 경우에는 즉시 의사에게 보고해야 합니다.',
      storage_method:
        '1. 어린이나 여성이 절대 만지는 일이 없도록 보관합니다.\n2. 품질 보존을 위해 다른 용기에 바꾸어 넣지 않으며 습기를 피하여 15~30℃에서 보관합니다.',
    },
    {
      name: {
        ko: '두타스테리드',
        en: 'Dutasteride',
      },
      efficacy: '성인 남성(만 18~50세)의 남성형 탈모증(안드로겐 탈모증)의 치료',
      dosage:
        '일반적으로 1일 1회 1정(0.5mg)을 식사와 무관하게 복용합니다.\n캡슐 내용물에 노출 시 구강 인두점막의 자극을 초래할 수 있으므로 약을 씹거나 쪼개지 않고 통째로 삼켜서 복용해야 합니다.',
      caution:
        '1. 소아 및 여성은 복용하면 안 됩니다.\n2. 임부 또는 임신 가능성이 있는 여성의 경우 남성 태아에 위험을 초래할 가능성이 있으므로 절대 복용하면 안 됩니다. 또한 부서진 약 조각을 만질 경우에도 피부를 통해 흡수될 수 있기 때문에 절대 만지면 안 됩니다.\n3. 신장이나 간 질환을 앓고 있거나 신장 또는 간 기능 이상이 있는 경우에는 사용에 주의해야 합니다.\n4. 임신을 계획하고 있는 성인 남성의 경우에도 생식능에 영향을 줄 수 있으므로 의사의 판단하에 신중히 복용해야 합니다.',
      side_effect:
        '1. 발기부전, 성욕감퇴, 사정 장애 등이 있을 수 있습니다.\n2. 남자라도 유방에 멍울이 만져지거나 커지는 현상 및 통증, 또는 유두에서 분비물이 나오는 등 유방의 변화가 있을 경우에는 즉시 의사에게 보고해야 합니다.',
      storage_method:
        '1. 어린이나 여성이 절대 만지는 일이 없도록 보관합니다.\n2. 품질 보존을 위해 다른 용기에 바꾸어 넣지 않으며 습기를 피하여 15~30℃에서 보관합니다.',
    },
    {
      name: {
        ko: '미녹시딜',
        en: 'Minoxidil',
      },
      efficacy:
        '성인(만 18세~55세) 남성 및 여성의 안드로겐 탈모증의 치료\n(5% 제재는 남성만 사용, 2~3% 제재는 남성과 여성 모두 사용)',
      dosage:
        '모발과 두피를 완전히 건조한 후, 남성은 0.5~1mL / 여성은 0.5ml를 1일 2회(아침, 저녁)씩 최소 4개월 동안 환부에만 바릅니다.\n단, 하루 총투여량은 남성 2mL, 여성은 1.3ml를 초과하지 않아야 합니다.',
      caution:
        '1. 임부 또는 임신 가능성이 있는 여성이나 수유부는 사용하면 안 됩니다.\n2. 심혈관계 질환(관상동맥 질환, 부정맥, 울혈 심부전, 심장판막 질환, 고혈압, 저혈압)이 있는 경우 사용하면 안 됩니다.\n3. 유전적 요인이 없는 탈모나 갑작스러운 부분 탈모에는 사용하면 안 됩니다.\n4. 두피 이상 (홍반, 염증, 감염, 자극 또는 통증, 건선, 일광화상)이 있는 경우 사용하면 안 됩니다.\n5. 사용 후에 랩이나 테이프 등으로 밀봉하며 안됩니다.\n6. 두피에 한하여 외용으로만 사용해야 합니다.\n7. 눈이나 점막 등 민감한 부위에 닿으면 즉시 깨끗한 물로 씻어내야 합니다.\n8. 헤어 스타일링 제품은 약이 마른 후 사용해야 합니다.\n9. 약의 건조를 위해 헤어드라이어를 쓰면 약의 효과가 감소될 수 있습니다.',
      side_effect:
        '1. 가장 흔한 부작용은 도포부위의 가려움증과 자극감입니다.\n2. 여성의 경우 두피 이외에 얼굴, 팔, 다리 등에 털이 나는 다모증이 나타날 수 있습니다.\n3. 설사, 구역, 구토 등의 소화기계 증상과 기관지염이나 호흡이 짧아지는 등의 호흡기 부작용이 발생할 수 있습니다.',
      storage_method:
        '1 어린이의 손이 닿지 않는 곳에 보관합니다.\n2. 인화성이 있으므로 화기를 피합니다.\n3. 직사광선을 피하고 습기가 적은 곳에서 실온 보관합니다.',
    },
  ],
  2: [
    {
      name: {
        ko: '미녹시딜',
        en: 'Minoxidil',
      },
      efficacy:
        '성인(만 18세~55세) 남성 및 여성의 안드로겐 탈모증의 치료\n(5% 제재는 남성만 사용, 2~3% 제재는 남성과 여성 모두 사용)',
      dosage:
        '모발과 두피를 완전히 건조한 후, 남성은 0.5~1mL / 여성은 0.5ml를 1일 2회(아침, 저녁)씩 최소 4개월 동안 환부에만 바릅니다.\n단, 하루 총투여량은 남성 2mL, 여성은 1.3ml를 초과하지 않아야 합니다.',
      caution:
        '1. 임부 또는 임신 가능성이 있는 여성이나 수유부는 사용하면 안 됩니다.\n2. 심혈관계 질환(관상동맥 질환, 부정맥, 울혈 심부전, 심장판막 질환, 고혈압, 저혈압)이 있는 경우 사용하면 안 됩니다.\n3. 유전적 요인이 없는 탈모나 갑작스러운 부분 탈모에는 사용하면 안 됩니다.\n4. 두피 이상 (홍반, 염증, 감염, 자극 또는 통증, 건선, 일광화상)이 있는 경우 사용하면 안 됩니다.\n5. 사용 후에 랩이나 테이프 등으로 밀봉하며 안됩니다.\n6. 두피에 한하여 외용으로만 사용해야 합니다.\n7. 눈이나 점막 등 민감한 부위에 닿으면 즉시 깨끗한 물로 씻어내야 합니다.\n8. 헤어 스타일링 제품은 약이 마른 후 사용해야 합니다.\n9. 약의 건조를 위해 헤어드라이어를 쓰면 약의 효과가 감소될 수 있습니다.',
      side_effect:
        '1. 가장 흔한 부작용은 도포부위의 가려움증과 자극감입니다.\n2. 여성의 경우 두피 이외에 얼굴, 팔, 다리 등에 털이 나는 다모증이 나타날 수 있습니다.\n3. 설사, 구역, 구토 등의 소화기계 증상과 기관지염이나 호흡이 짧아지는 등의 호흡기 부작용이 발생할 수 있습니다.',
      storage_method:
        '1 어린이의 손이 닿지 않는 곳에 보관합니다.\n2. 인화성이 있으므로 화기를 피합니다.\n3. 직사광선을 피하고 습기가 적은 곳에서 실온 보관합니다.',
    },
  ],
  3: [
    {
      name: {
        ko: '실데나필시트르산염',
        en: 'Sildenafil Citrate',
      },
      efficacy: '발기부전의 치료',
      dosage:
        '1일 1회, 성행위 약 1시간 전에 25∼50mg을 경구 투여합니다.\n경우에 따라 성행위 4시간~30분 전에 투여도 가능하며, 유효성과 내약성에 따라 용량을 증감할 수 있습니다.',
      caution:
        '1. 한 번에 두 종류 이상의 경구용 발기부전 치료제를 복용하지 말아야 합니다.\n2. 복용 후 4시간 이상 발기가 사그라지지 않거나, 시력 또는 청력이 감퇴되는 경우 의사에게 알려야 합니다.\n3. 음식물과 복용하면 공복 시에 복용했을 때 보다 효과가 나타나기 시작하는 시간이 지연될 수 있습니다.\n4. 경미한 혈관 확장 작용이 있기 때문에 알코올과 같이 복용하지 않는 것이 좋습니다.\n5. 먹는 무좀치료제는 발기부전 치료제의 혈중 농도를 상승시킬 수 있습니다.\n6. 전립선비대증에 사용되는 α-차단제(예: 독사조신메실산염, 탐스로신 염산염, 알푸조신 등)와 함께 복용 시에는 저혈압의 가능성을 주의하여야 합니다.\n7. 니트로글리세린(협심증약), 아밀나이트레이트 (혈관확장제), 질산이소소르비드(협심증, 심근경색약) 등의 유기 질산염을 포함하는 의약품을 복용하는 경우 혈압이 급격하게 떨어져 치명적인 부작용을 일으킬 수 있습니다.\n8. 최근 심근경색, 뇌졸중이 발생한 경우가 있거나, 협심증, 심부전, 고혈압, 저혈압을 앓고 있는 경우 심혈관계 위험이 증가할 수 있습니다.\n9. 비동맥 전방 허혈성 시신경증, 망막질환(당뇨병성 망막질환 등) 환자는 복용 전에 의사에게 질환에 대해 알려야 합니다.\n10. 여성이나 18세 미만의 청소년 또는 소아는 복용하면 안 됩니다.',
      side_effect:
        '1. 가장 흔한 부작용은 두통, 안면홍조, 소화불량이며 부작용이 나타났을 때에는 복용을 중단하고, 의사와 상담하는 것이 좋습니다.\n2. 낮은 빈도로 비출혈(코피), 어지러움, 복통, 안구충혈, 시각이상 등이 나타날 수 있습니다.',
      storage_method:
        '1. 어린이이나 소아의 손이 닿지 않는 곳에 보관합니다.\n2. 다른 용기에 바꾸어 넣는 것은 오복용으로 인한 사고의 원인이 될 수 있습니다.',
    },
    {
      name: {
        ko: '타다라필',
        en: 'Tadalafil',
      },
      efficacy: '발기부전의 치료',
      dosage:
        '1일 1회, 적어도 성행위 30분 전에 10∼20mg을 경구 투여합니다.\n권장 용량은 10mg이며, 충분한 효과가 나타나지 않는다면 20mg까지도 가능합니다.',
      caution:
        '1. 한 번에 두 종류 이상의 경구용 발기부전 치료제를 복용하지 말아야 합니다.\n2. 복용 후 4시간 이상 발기가 사그라지지 않거나, 시력 또는 청력이 감퇴되는 경우 의사에게 알려야 합니다.\n3. 음식물과 복용하면 공복 시에 복용했을 때 보다 효과가 나타나기 시작하는 시간이 지연될 수 있습니다.\n4. 경미한 혈관 확장 작용이 있기 때문에 알코올과 같이 복용하지 않는 것이 좋습니다.\n5. 먹는 무좀치료제는 발기부전 치료제의 혈중 농도를 상승시킬 수 있습니다.\n6. 전립선비대증에 사용되는 α-차단제(예: 독사조신메실산염, 탐스로신 염산염, 알푸조신 등)와 함께 복용 시에는 저혈압의 가능성을 주의하여야 합니다.\n7. 니트로글리세린(협심증약), 아밀나이트레이트 (혈관확장제), 질산이소소르비드(협심증, 심근경색약) 등의 유기 질산염을 포함하는 의약품을 복용하는 경우 혈압이 급격하게 떨어져 치명적인 부작용을 일으킬 수 있습니다.\n8. 최근 심근경색, 뇌졸중이 발생한 경우가 있거나, 협심증, 심부전, 고혈압, 저혈압을 앓고 있는 경우 심혈관계 위험이 증가할 수 있습니다.\n9. 비동맥 전방 허혈성 시신경증, 망막질환(당뇨병성 망막질환 등) 환자는 복용 전에 의사에게 질환에 대해 알려야 합니다.\n10. 여성이나 18세 미만의 청소년 또는 소아는 복용하면 안 됩니다.',
      side_effect:
        '1. 가장 흔한 부작용은 두통, 안면홍조, 소화불량이며 부작용이 나타났을 때에는 복용을 중단하고, 의사와 상담하는 것이 좋습니다.\n2. 낮은 빈도로 비출혈(코피), 어지러움, 복통, 안구충혈, 시각이상 등이 나타날 수 있습니다.',
      storage_method:
        '1. 어린이이나 소아의 손이 닿지 않는 곳에 보관합니다.\n2. 다른 용기에 바꾸어 넣는 것은 오복용으로 인한 사고의 원인이 될 수 있습니다.',
    },
    {
      name: {
        ko: '바데나필염산염',
        en: 'Vardenafil Hydrochloride',
      },
      efficacy: '발기부전의 치료',
      dosage:
        '1일 1회, 성행위 25분 ~ 1시간 전에 10mg을 경구 투여합니다.\n유효성과 내약성에 따라 용량을 5~20mg까지 증감할 수 있으며, 최대 권장용량은 20mg입니다.',
      caution:
        '1. 한 번에 두 종류 이상의 경구용 발기부전 치료제를 복용하지 말아야 합니다.\n2. 복용 후 4시간 이상 발기가 사그라지지 않거나, 시력 또는 청력이 감퇴되는 경우 의사에게 알려야 합니다.\n3. 음식물과 복용하면 공복 시에 복용했을 때 보다 효과가 나타나기 시작하는 시간이 지연될 수 있습니다.\n4. 경미한 혈관 확장 작용이 있기 때문에 알코올과 같이 복용하지 않는 것이 좋습니다.\n5. 먹는 무좀치료제는 발기부전 치료제의 혈중 농도를 상승시킬 수 있습니다.\n6. 전립선비대증에 사용되는 α-차단제(예: 독사조신메실산염, 탐스로신 염산염, 알푸조신 등)와 함께 복용 시에는 저혈압의 가능성을 주의하여야 합니다.\n7. 니트로글리세린(협심증약), 아밀나이트레이트 (혈관확장제), 질산이소소르비드(협심증, 심근경색약) 등의 유기 질산염을 포함하는 의약품을 복용하는 경우 혈압이 급격하게 떨어져 치명적인 부작용을 일으킬 수 있습니다.\n8. 최근 심근경색, 뇌졸중이 발생한 경우가 있거나, 협심증, 심부전, 고혈압, 저혈압을 앓고 있는 경우 심혈관계 위험이 증가할 수 있습니다.\n9. 비동맥 전방 허혈성 시신경증, 망막질환(당뇨병성 망막질환 등) 환자는 복용 전에 의사에게 질환에 대해 알려야 합니다.\n10. 여성이나 18세 미만의 청소년 또는 소아는 복용하면 안 됩니다.',
      side_effect:
        '1. 가장 흔한 부작용은 두통, 안면홍조, 소화불량이며 부작용이 나타났을 때에는 복용을 중단하고, 의사와 상담하는 것이 좋습니다.\n2. 낮은 빈도로 비출혈(코피), 어지러움, 복통, 안구충혈, 시각이상 등이 나타날 수 있습니다.',
      storage_method:
        '1. 어린이이나 소아의 손이 닿지 않는 곳에 보관합니다.\n2. 다른 용기에 바꾸어 넣는 것은 오복용으로 인한 사고의 원인이 될 수 있습니다.',
    },
    {
      name: {
        ko: '아바나필',
        en: 'Avanafil',
      },
      efficacy: '발기부전의 치료',
      dosage:
        '1일 1회, 성행위 30분 전에 100mg을 경구 투여합니다.\n유효성과 내약성에 따라 용량을 200mg까지 증가할 수 있습니다.',
      caution:
        '1. 한 번에 두 종류 이상의 경구용 발기부전 치료제를 복용하지 말아야 합니다.\n2. 복용 후 4시간 이상 발기가 사그라지지 않거나, 시력 또는 청력이 감퇴되는 경우 의사에게 알려야 합니다.\n3. 음식물과 복용하면 공복 시에 복용했을 때 보다 효과가 나타나기 시작하는 시간이 지연될 수 있습니다.\n4. 경미한 혈관 확장 작용이 있기 때문에 알코올과 같이 복용하지 않는 것이 좋습니다.\n5. 먹는 무좀치료제는 발기부전 치료제의 혈중 농도를 상승시킬 수 있습니다.\n6. 전립선비대증에 사용되는 α-차단제(예: 독사조신메실산염, 탐스로신 염산염, 알푸조신 등)와 함께 복용 시에는 저혈압의 가능성을 주의하여야 합니다.\n7. 니트로글리세린(협심증약), 아밀나이트레이트 (혈관확장제), 질산이소소르비드(협심증, 심근경색약) 등의 유기 질산염을 포함하는 의약품을 복용하는 경우 혈압이 급격하게 떨어져 치명적인 부작용을 일으킬 수 있습니다.\n8. 최근 심근경색, 뇌졸중이 발생한 경우가 있거나, 협심증, 심부전, 고혈압, 저혈압을 앓고 있는 경우 심혈관계 위험이 증가할 수 있습니다.\n9. 비동맥 전방 허혈성 시신경증, 망막질환(당뇨병성 망막질환 등) 환자는 복용 전에 의사에게 질환에 대해 알려야 합니다.\n10. 여성이나 18세 미만의 청소년 또는 소아는 복용하면 안 됩니다.',
      side_effect:
        '1. 가장 흔한 부작용은 두통, 안면홍조, 소화불량이며 부작용이 나타났을 때에는 복용을 중단하고, 의사와 상담하는 것이 좋습니다.\n2. 낮은 빈도로 비출혈(코피), 어지러움, 복통, 안구충혈, 시각이상 등이 나타날 수 있습니다.',
      storage_method:
        '1. 어린이이나 소아의 손이 닿지 않는 곳에 보관합니다.\n2. 다른 용기에 바꾸어 넣는 것은 오복용으로 인한 사고의 원인이 될 수 있습니다.',
    },
  ],
  4: [
    {
      name: {
        ko: '에스트로겐',
        en: 'Estrogen',
      },
      efficacy:
        '난자의 성장과 배란, 이동, 수정을 억제하고 수정란이 착상하기에 부적합한 환경을 만들어 피임효과를 나타냅니다.',
      division: '에스트로겐의 양에 따라서 저용량(0.03mg), 초저용량(0.02mg) 피임제로 구분합니다.',
      caution:
        '아래에 해당하는 경우 에스트로겐이 포함된 경구 피임약을 복용하면 안 됩니다.\n1. 임부 또는 임신 가능성이 있는 경우 \n2. 35세 이상의 흡연자일 경우 \n3. 유방암, 자궁내막암, 기타 여성호르몬 의존성 종양이 있는 경우 \n4. 간 질환(간경화, 간염, 간암)이나 담낭 질환이 있는 경우 \n5. 혈전성 질환이나 뇌졸중, 심혈관계 질환이 있는 경우 \n6. 편두통이 심한 경우',
      side_effect:
        '1. 에스트로겐 함량이 높을수록 혈전의 위험성이 증가합니다.\n2. 유방팽만감, 유방통, 편두통, 메스꺼움, 구토, 부정출혈, 고혈압 등이 있을 수 있습니다.',
    },
    {
      name: {
        ko: '프로게스틴',
        en: 'Progestin',
      },
      efficacy:
        '배란을 억제하고 정자가 자궁 내로 유입되는 것을 차단합니다. 그리고 수정란이 착상하기에 부적합한 환경을 만들어 피임효과를 나타냅니다.',
      division:
        '프로게스틴의 종류에 따라 1~4세대로 분류하며. 현재 2~4세대에 한하여 사용됩니다.\n2세대(레보노르게스트렐)는 혈전의 위험성은 적지만 안드로겐 관련 부작용 위험성이 상대적으로 높습니다.\n3세대(게스토덴, 데소게스트렐)는 안드로겐 부작용을 감소하여 에스트로겐 효과가 확대되었지만, 이로 인해 혈전의 위험성이 상대적으로 높습니다.\n4세대(디에노게스트, 드로스피레논)는 안드로겐 부작용을 감소하여 피임 이외의 월경전 불쾌장애, 여드름 치료에 사용될 수 있습니다.',
      caution:
        '아래에 해당하는 경우 프로게스틴이 포함된 경구 피임약을 복용하면 안 됩니다.\n1. 임부 또는 임신 가능성이 있는 경우 \n2. 35세 이상의 흡연자일 경우 \n3. 유방암, 자궁내막암, 기타 여성호르몬 의존성 종양이 있는 경우 \n4. 간 질환(간경화, 간염, 간암)이나 담낭 질환이 있는 경우 \n5. 혈전성 질환이나 뇌졸중, 심혈관계 질환이 있는 경우 \n6. 편두통이 심한 경우',
      side_effect:
        '1. 프로게스틴 함량이 높을수록 여드름, 체모 증가 등의 안드로겐 활성 부작용이 발생할 수 있습니다. \n2. 식욕 및 체중 증가, 체온 상승, 부종, 피로감, 우울감 등이 있을 수 있습니다.',
    },
  ],
  5: [
    {
      name: {
        ko: '오르리스타트',
        en: 'Orlistat',
      },
      efficacy:
        '비만환자의 체중감소, 체중유지를 포함한 비만치료 , 체중 재증가의 위험 감소\n(BMI 30 ㎏/m² 이상 / 고혈압, 당뇨, 고지혈증이 있을 경우 27㎏/m² 이상)',
      dosage:
        '지방을 함유하는 식사와 함께 복용하거나 식사 후 1시간 이내에 1회 120 mg, 1일 3회 경구 투여합니다.\n식사를 거르거나 지방이 함유되지 않은 식사를 하는 경우에는 복용하지 않을 수 있습니다.',
      caution:
        '1. 기질적인 비만원인(갑상선기능저하증)이 있는 환자, 만성흡수불량 증후군 환자, 담즙분비정지 환자는 복용하면 안 됩니다.\n2. 해당 성분에 과민반응이 있는 환자는 복용하면 안 됩니다.\n3. 임부 또는 임신 가능성이 있는 여성이나 수유부는 사용하면 안 됩니다.\n4. 간 기능 이상의 징후(식욕 감퇴, 가려움증, 황달, 어두운 색 소변, 연한 색 대변, 상복부 우측 통증)가 나타날 경우 의사에게 알립니다.\n5. 과수산뇨증 또는 수산칼슘 신결석력이 있는 환자는 주의해야 합니다.\n6. 경구 항응고제와 함께 사용하는 환자는 혈액응고계수(INR수치) 변화를 모니터링해야 합니다.\n7. 식이지침을 준수합니다.\n8. 지방이 함유된 식사를 한 지 1시간 이내에 사용을 하고, 1시간이 지나도록 먹지 못했다면 다음 사용시간에 사용합니다.\n9. 지용성 비타민을 함유한 종합 비타민 보충제 복용을 권장합니다.',
      side_effect:
        '1. 흡수되지 않은 지방성분이 변으로 배출이 되기 때문에 대변 중에 과량의 지방이 존재하게 되고, 이로 인해 변을 자주 보게 된다거나 묽은 변을 보게 되어 일상적인 생활이 불편하게 될 수도 있습니다. 또한 복부 팽만감이나 복통 등이 나타날 수 있습니다.\n2. 간 기능 저하 및 간염이나 간부전 등의 중증 간 손상이 나타날 수 있으므로 가려움, 피부 및 눈의 황달, 열, 무력감, 구토, 피로, 어두운 색 소변, 식욕 감퇴, 연한 색 대변 등이 나타날 경우에는 즉시 사용을 즉각 중단하고 의사·약사와 상담하여야 합니다.',
      storage_method:
        '1 어린이의 손이 닿지 않는 곳에 보관합니다.\n2. 빛과 습기로부터 보호하기 위해 블리스터 포장을 차광하여 보관합니다.\n3. 다른 용기에 바꾸어 넣는 것은 오복용으로 인한 사고의 원인이 될 수 있습니다.',
    },
    {
      name: {
        ko: '리라글루티드',
        en: 'Liraglutide',
      },
      efficacy:
        '성인 비만환자의 칼로리 저감 식이요법 및 신체 활동 증대의 보조\n(BMI 30 ㎏/m² 이상 / 고혈압, 당뇨, 고지혈증이 있을 경우 27㎏/m² 이상)',
      dosage:
        '1일 1회 식사와 관계없이 복부나 허벅지의 전면, 팔 바깥쪽에 주사합니다.\n가능하면 매일 같은 시간에 투여하는 것이 좋으며, 이전 주사한 부위와 같은 부위에 주사하지 않도록 합니다.\n주사 용량을 완전히 투여하기 위해서 주사침을 찌른 후 약 6초간 기다립니다.\n용량은 1일 1회 0.6mg으로 시작, 적어도 일주일 이상의 간격을 두고 0.6 mg씩 증량하여 최대 1일 1회 3.0 mg까지 투여량을 높입니다.\n만약 적정한 용량으로 충분한 기간 동안 투여했음에도 체중감량이 되지 않는다면 치료를 중단해야 합니다.',
      caution:
        '1. 임신 중에 사용하면 안 됩니다.\n2. 임신을 원하거나 임신한 경우, 치료를 중단합니다.\n3. 갑상선 수질암의 개인 또는 가족력이 있거나 다발성 내분비선종증 환자는 투여해서는 안됩니다.\n4. 갑상선질환, 췌장염, 담석증, 담낭염, 염증성장질환, 위부전마비 등 중증의 위장관 질병 병력이 있는 환자는 투여 전 의사와 상의합니다.\n5. 당뇨병 환자는 저혈당 위험이 증가하므로 각별한 주의가 필요합니다.',
      side_effect:
        '1. 가장 흔한 이상반응은 메스꺼움, 구토, 변비, 설사입니다. 대부분의 경우 며칠 혹은 몇 주 후면 사라집니다.\n2. 주사부위에 통증이나, 가려움증, 화끈거림, 빨개짐 등이 나타날 수 있으며 만약 며칠이 지나도 나아지지 않거나 심해질 경우 사용을 중단하고 의사와 상담하여야 합니다.\n3. 드물게 주사 직후 두드러기, 입과 목의 부종, 가슴 조임, 호흡곤란 등의 알러지 반응이 나타날 수 있습니다. 알러지 반응이 의심될 경우, 즉시 응급실을 방문합니다.\n4. 낮은 빈도로 불면증, 현기증, 미각 이상, 무력증, 피로감, 담석증, 담낭염, 췌장염, 탈수, 빈맥(빠른 심박수), 급성 신장애가 발생할 수 있습니다.',
      storage_method:
        '1. 어린이의 손이 닿지 않는 곳에 보관합니다.\n2. 매 주사마다 새 주사침을 사용하며, 사용한 바늘은 분리하여 폐기합니다.\n3. 사용 후에는 바늘 분리한 상태로 펜의 뚜껑을 꼭 닫아서 보관합니다.\n4. 투명, 무색 혹은 거의 무색으로 보이지 않는다면, 사용하면 안 됩니다.\n5. 개봉 후엔 차광 상태로 냉장(냉동X) 보관하며, 1개월 동안 사용할 수 있습니다.\n6. 동결된 적이 있는 경우 사용하면 안 됩니다.',
    },
  ],
};
