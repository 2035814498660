import { drug } from 'components/templates/PhilingSubMainTemplate/drug';

export const CATEGORIES = [
  {
    label: '탈모',
    isActive: true,
    items: drug['1'],
    to: '1',
  },
  // {
  //   label: '성기능',
  //   isActive: false,
  //   items: drug['3'],
  //   to: '3',
  // },
  {
    label: '피임',
    isActive: false,
    items: drug['4'],
    to: '4',
  },
  {
    label: '다이어트',
    isActive: false,
    items: drug['5'],
    to: '5',
  },
];
