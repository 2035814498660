import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

const IconArrowSmall = '/assets/images/icons/icon_arrow_small.png';

export default function FaqCard({ question, link, newTab }) {
  const router = useRouter();

  return (
    <Card onMouseUp={() => (newTab ? window.open(link, '_blank') : router.push(link))}>
      {question}
      <img src={IconArrowSmall} alt="faq상세" />
    </Card>
  );
}

const Card = styled.div`
  border-radius: 2rem;
  background-color: #fff;
  margin-bottom: 1rem;
  display: flex;
  padding: 1.5rem 2.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:active {
    transform: translateY(0.3rem);
  }

  img {
    width: 3rem;
  }
`;
