import React from 'react';
import styled, { keyframes } from 'styled-components';

export default function InfiniteSentenceBanner({ sentence, theme = '#e1ea68' }) {
  return (
    <Banner theme={theme}>
      <Wrapper>
        <Marquee>
          <Sentence>{sentence}</Sentence>
          <Sentence>{sentence}</Sentence>
          <Sentence>{sentence}</Sentence>
          <Sentence>{sentence}</Sentence>
          <Sentence>{sentence}</Sentence>
          <Sentence>{sentence}</Sentence>
        </Marquee>
      </Wrapper>
    </Banner>
  );
}

const marquee = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-50%, 0, 0);
  }
`;

const Banner = styled.div`
  font-size: 1.2rem;
  background-color: ${({ theme }) => theme};
  margin: 0 -1.4rem;
  padding: 1.5rem 0;
`;

const Wrapper = styled.div`
  overflow: hidden;
  max-width: 100%;
`;

const Marquee = styled.div`
  white-space: nowrap;
  overflow: hidden;
  display: inline-flex;
  animation: ${marquee} 10s linear infinite;
`;

const Sentence = styled.div`
  margin-right: 5rem;
  display: inline-flex;
`;
